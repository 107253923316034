
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,800');

@import "buttons";
@import "embed";
@import "icons";
@import "navigation";


html {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
}

body {
  margin-bottom: 23rem;

  @include media-breakpoint-down("sm") {
      margin-bottom: 36rem;
  }
}

.fixed-bottom {
  position: absolute;
  bottom: 0;
}

.bordered {
  border-style: solid;
  border-color: $body-color;
  border-width: 0;

  &-top {
    border-top-width: $border-width;
  }
  &-bottom {
    border-bottom-width: $border-width;
  }
}

.bg-white {
  background-color: $white;
}


.nav-link-plain {
  padding: default;
  color: $body-color;

  transition: color .25s ease-out .35s;

  @include hover-focus {
    color: $brand-primary;
    text-decoration: none;

    transition: color .125s ease-in;
  }
}

.jumbotron,
.jumbotron-fluid {
  position: relative;
}

.jumbotron-backdrop-image {
  width: 100%;
  object-fit: cover;
}

.image-display {
  min-height: 45vh;
  max-height: 70vh;

  &-25 {
    min-height: 15vh;
    max-height: 20vh;
  }
  &-50 {
    min-height: 30vh;
    max-height: 50vh;
  }
  &-75 {
    min-height: 50vh;
    max-height: 75vh;
  }
}

.jumbotron-backdrop-overlay {
  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: center;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.drop-shadow {
  box-shadow: 0 .25rem .5rem transparentize(darken($brand-primary, 25%), .8);
  box-shadow:
    0 .2rem .2rem transparentize(darken($brand-primary, 25%), .95),
    0 .4rem .4rem transparentize(darken($brand-primary, 25%), .95),
    0 .8rem .8rem transparentize(darken($brand-primary, 25%), .95),
    0 .8rem 1.5rem transparentize(darken($brand-primary, 25%), .9),
    0 1.5rem 1.5rem transparentize(darken($brand-primary, 25%), .9) !important;
}

.hover-fade {
  transition: opacity .25s ease-in .25s;
  &:hover {
    opacity: .8;
    transition: opacity .2s ease-out;
  }
}

.text-shadow {
  text-shadow:
    0 .25rem .25rem transparentize(darken($brand-primary, 25%), .9),
    0 .5rem .5rem transparentize(darken($brand-primary, 25%), .9),
    0 1rem 1rem transparentize(darken($brand-primary, 25%), .9),
    0 1rem 2rem transparentize(darken($brand-primary, 25%), .85),
    0 2rem 2rem transparentize(darken($brand-primary, 25%), .85);

  &-inverse {
    text-shadow:
      0 .25rem .25rem transparentize(white, .9),
      0 .5rem .5rem transparentize(white, .9),
      0 1rem 1rem transparentize(white, .9),
      0 1rem 2rem transparentize(white, .85),
      0 2rem 2rem transparentize(white, .85);
  }
}
