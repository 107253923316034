
@include media-breakpoint-down("xs") {
  .navbar-toggleable-md {
    & > .container {
      width: 100%;
    }
    .navbar-nav {
      padding-left: $spacer-x;
    }
  }
}

$navbar-container-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
);

@include media-breakpoint-down("md") {
  .navbar-toggler {
    cursor: pointer;
  }
  .navbar-brand,
  .navbar-toggler {
    z-index: $zindex-navbar + 3;
  }
  .navbar-toggler-right {
    right: 0;
    margin-bottom: 0;
    position: static;
  }

  .navbar-toggleable-md {
    & > .container {
      position: static;
      display: flex;
    }
    .navbar-toggle {

      & + .navbar-toggler + .navbar-collapse {
        .navbar-nav {
          margin-left: auto;
          margin-right: auto;
          @include make-container-max-widths($breakpoints: $navbar-container-breakpoints);
        }

        position: absolute;
        display: block;
        z-index: $zindex-navbar;
        left: 0;
        right: 0;
        padding-top: 2.5rem;

        transform: translate3d(0, -15.5rem, 0);
        transition: transform .45s cubic-bezier(0.260, 0.045, 0.750, -0.515);
      }
      &:checked + .navbar-toggler {
        color: $brand-primary;
      }
      &:checked + .navbar-toggler + .navbar-collapse {
        transform: translate3d(0, 0, 0);
        transition: transform .45s cubic-bezier(0.060, 1.525, 0.675, 0.990);
      }
    }
  }
}
