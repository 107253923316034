
$body-color: #010e12;
$brand-primary: #5a737a;

$font-family-sans-serif: "Fira Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-weight-base: 300;
$font-weight-bold: 800;
$headings-font-weight: 800;

$text-muted: transparentize($body-color, .65);

$border-width: .125rem;

$border-radius:          .125rem;
$border-radius-lg:       .25rem;
$border-radius-sm:       .0625rem;

$spacer: 1.5rem;
