
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-only($breakpoint) {
    .btn-#{$breakpoint}-block {
      display: block;
    }
  }
}

.btn-dark {
  @include button-variant($body-bg, $body-color, $body-color);
}

.btn-outline-dark {
  @include button-outline-variant($body-color);
}

.btn-light {
  @include button-variant($body-color, $body-bg, $body-bg);
}

.btn-outline-light {
  @include button-outline-variant($body-bg, $body-color);
}
