
.icon {
  svg {
    max-height: 1.5rem;
    fill: $body-color;
    fill: currentColor;
  }
}

.disabled .icon {
  svg {
    fill: transparentize($body-color, .85);
  }
}
